import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail } from '../customization'

export const getCampaignAnalyticsData = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.getCampaignAnalytics,
        fetchPolicy: 'no-cache',
        variables: { ...req }
      })
      .then((response: any) => {
        dispatch({
          type: actionTypes.GET_ANALYTICS_CAMPAIGN_SUCCESS,
          payload: response?.data?.campaignAnalytics?.data
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.addCampaignFailed, error.message))
      })
  }
}

// Get campaign analytics data by ids also prevent the selected filter if already fetch
export const getCampaignsAnalyticsDataByIds = (client: any, req: any, fetchedIDs: any) => {
  return async (dispatch: any, state: any) => {
    dispatch(loadingStart())

    const stateValues = state()
    const queries = req.map((payload: any) => {
      return client.query({
        query: DotGQL.getCampaignAnalytics,
        fetchPolicy: 'no-cache',
        variables: { ...payload }
      })
    })

    try {
      const result = await Promise.all(queries)

      // Merge country data
      const mergeCountryData = (target: any[], source: any[]): void => {
        source.forEach((country) => {
          const countryName = Object.keys(country)[0]
          const existingCountry = target.find((value) => Object.keys(value)[0] === countryName)
          if (existingCountry) {
            existingCountry[countryName] += country[countryName]
          } else {
            target.push({ ...country })
          }
        })
      }

      // Merge date data
      const mergeDateData = (target: any[], source: any[], key: string): void => {
        source.forEach((dateItem) => {
          const existingDate = target.find((value) => value.date === dateItem.date)
          if (existingDate) {
            existingDate[key] += dateItem[key]
          } else {
            target.push({ ...dateItem })
          }
        })
      }

      // Merge filter data
      const mergeFilterData = (target: any[], source: any[]): void => {
        source.forEach((item) => {
          const existingItem = target.find((value) => value.title === item.title)
          if (existingItem) {
            existingItem.total_impressions = (existingItem.total_impressions ?? 0) + (item.total_impressions ?? 0)
            existingItem.total_clicks = (existingItem.total_clicks ?? 0) + (item.total_clicks ?? 0)
            existingItem.total_spends = (existingItem.total_spends ?? 0) + (item.total_spends ?? 0)
            existingItem.total_ctr = (existingItem.total_ctr ?? 0) + (item.total_ctr ?? 0)
            existingItem.total_cpc = (existingItem.total_cpc ?? 0) + (item.total_cpc ?? 0)
          } else {
            target.push({ ...item })
          }
        })
      }

      // Already fetched campaign analytics data merge result
      const existingAnalyticsData = fetchedIDs.map((id: any) => {
        return {
          data: {
            campaignAnalytics: {
              data: stateValues.campaignDetailReducer.campaignAnalyticsDataByIDs[id]
            }
          }
        }
      })

      // Merge all campaign analytics data
      const finalCampaignAnalytics = [...result, ...existingAnalyticsData].reduce(
        (allCampaignsAnalyticsData, analyticData, index) => {
          let existingAnalyticData = allCampaignsAnalyticsData[0] ?? {},
            existingAllAnalytics = allCampaignsAnalyticsData[1] ?? {}
          const data = analyticData.data.campaignAnalytics.data

          if (!data) return [existingAnalyticData, existingAllAnalytics]

          const campaignId = req[index] ? req[index].campaign_id : fetchedIDs[index - result.length]
          existingAllAnalytics[campaignId] = data

          existingAnalyticData.total_impressions =
            (existingAnalyticData.total_impressions ?? 0) + data.total_impressions
          existingAnalyticData.total_spends = (existingAnalyticData.total_spends ?? 0) + data.total_spends
          existingAnalyticData.total_clicks = (existingAnalyticData.total_clicks ?? 0) + data.total_clicks
          existingAnalyticData.total_cpc = !existingAnalyticData.total_clicks ? 0 : existingAnalyticData.total_spends / existingAnalyticData.total_clicks || 0
          existingAnalyticData.total_ctr = (existingAnalyticData.total_ctr ?? 0) + data.total_ctr

          // Countries data
          if (data.click_countries) {
            if (!existingAnalyticData.click_countries) existingAnalyticData.click_countries = []
            mergeCountryData(existingAnalyticData.click_countries, data.click_countries)
          }

          // Impression countries data
          if (data.impression_countries) {
            if (!existingAnalyticData.impression_countries) existingAnalyticData.impression_countries = []
            mergeCountryData(existingAnalyticData.impression_countries, data.impression_countries)
          }

          // Date data
          if (data.date_impressions) {
            if (!existingAnalyticData.date_impressions) existingAnalyticData.date_impressions = []
            mergeDateData(existingAnalyticData.date_impressions, data.date_impressions, 'impressions')
          }

          // Clicks data
          if (data.date_clicks) {
            if (!existingAnalyticData.date_clicks) existingAnalyticData.date_clicks = []
            mergeDateData(existingAnalyticData.date_clicks, data.date_clicks, 'clicks')
          }

          // Filter data
          if (data.filter_data) {
            if (!existingAnalyticData.filter_data) existingAnalyticData.filter_data = {}

            const categories = ['campaign', 'creative', 'day', 'month', 'year', 'device_categories', 'geographics']
            categories.forEach((category) => {
              if (data.filter_data[category]) {
                if (!existingAnalyticData.filter_data[category]) {
                  existingAnalyticData.filter_data[category] = []
                }
                mergeFilterData(existingAnalyticData.filter_data[category], data.filter_data[category])
              }
            })
          }

          return [existingAnalyticData, existingAllAnalytics]
        },
        []
      )

      dispatch({
        type: actionTypes.GET_ANALYTICS_CAMPAIGN_SUCCESS,
        payload: finalCampaignAnalytics[0]
      })

      dispatch({
        type: actionTypes.CAMPAIGN_ANALYTICS_BY_IDS,
        payload: finalCampaignAnalytics[1]
      })

      dispatch(loadingStop())
    } catch (error: any) {
      dispatch(loadingStop())
      dispatch(notificationFail(NOTIFICATIONS.ERROR.addCampaignFailed, error.message))
    }
  }
}
