import LogoutIcon from '@mui/icons-material/Logout'
import { Card, CardContent, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutUser, MENU_OPEN } from '../../../../store/actions'
import ConfirmDialog from '../../../../views/modals/ConfirmDialog'
import { subMenu } from '../../../menu-items'
import UserAvatar from '../../../ui-components/UserAvatar'
import MenuList from '../MenuList'
import { clearCampaignList, clearStore } from '../../../../store/actions/campaign/list'

const CardStyle = styled(Card)(({ theme }: any) => ({
  background: theme.palette.primary.light,
  marginBottom: '22px',
  position: 'relative'
}))

const MenuCard = () => {
  const dispatch: any = useDispatch()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const userDetails = useSelector((state: any) => state.settingReducer.userDetails)

  const handleUserLogout = () => {
    navigate('/')
    dispatch(logoutUser());
    dispatch({ type: MENU_OPEN, id: 'menu-dashboard' })
    dispatch(clearCampaignList())
    dispatch(clearStore())
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <CardStyle className="sidebar_card" sx={{ padding: '0 12px 0px 16px' }}>
      <Box>
        <MenuList menuItem={subMenu} />
      </Box>
      <CardContent sx={{ p: 2 }}>
        <List sx={{ p: 0, m: 0 }}>
          <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
            <ListItemAvatar sx={{ mt: 0 }}>
              <UserAvatar
                name={userDetails?.full_name ? userDetails?.full_name : userDetails?.email}
                profile_image={userDetails?.profile_image}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ mt: 0 }}
              primary={
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#344054'
                  }}
                >
                  {userDetails?.full_name}
                </Typography>
              }
              secondary={
                <Typography variant="caption" style={{ color: '#475467' }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{userDetails?.email}</div>
                </Typography>
              }
            />
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation()
                handleClickOpen()
              }}
            >
              <LogoutIcon />
            </IconButton>
          </ListItem>
        </List>
      </CardContent>
      {open && <ConfirmDialog open={open} handleClose={handleClose} onConform={handleUserLogout} />}
    </CardStyle>
  )
}
export default MenuCard
