import { combineReducers } from 'redux'
import accountReducer from './accountReducer'
import apolloClient from './apolloClient'
import authReducer from './authReducer'
import CreativeReducer from './CreativeReducer'
// reducer import
import accountCountReducer from './admin/accountCount'
import campaignCreatedReducer from './admin/campaignCreated'
import dashboardCountReducer from './admin/dashboardCount'
import dashboardReducer from './admin/dashboardReducer'
import campaignDetailReducer from './campaignDetailReducer'
import campaignReducer from './campaignReducer'
import commonReducer from './commonReducer'
import CreativeDetailReducer from './creativeDetailReducer'
import customizationReducer from './customizationReducer'
import settingReducer from './settingReducer'
import paymentReducer from './stripeReducer'
import dashboarCountReducer from './user/dashboardCount'
import userReducer from './userReducer'
import onBoardingReducer from './user/onBoarding'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  campaignCreatedReducer: campaignCreatedReducer,
  dashboarCountReducer: dashboarCountReducer,
  accountCountReducer: accountCountReducer,
  dashboardCountReducer: dashboardCountReducer,
  apolloClient: apolloClient,
  customization: customizationReducer,
  CreativeReducer: CreativeReducer,
  authReducer: authReducer,
  settingReducer: settingReducer,
  userReducer: userReducer,
  accountReducer: accountReducer,
  paymentReducer: paymentReducer,
  campaignReducer: campaignReducer,
  dashboardReducer: dashboardReducer,
  campaignDetailReducer: campaignDetailReducer,
  CreativeDetailReducer: CreativeDetailReducer,
  commonReducer: commonReducer,
  onBoardingReducer: onBoardingReducer,
})

export default reducer
