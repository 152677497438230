const TrustPilotDark = process.env.PUBLIC_URL + '/images/TrustPilotDark.svg'
const TrustPilotLight = process.env.PUBLIC_URL + '/images/TrustPilotLight.svg'

export default function TrustpilotRating(props: any) {
  return (
    <img
      src={props.textColor === 'dark' ? TrustPilotDark : TrustPilotLight}
      alt="TrustPilot"
      style={{ height: '74px' }}
    />
  )
}
