// action - state management
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'

export const initialState = {
  transactions: []
}

const paymentReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case actionTypes.RESET_STATE : return initialState
    
    case actionTypes.GETTING_TRANSACTION_SUCCESS:
      return updateObject(state, {
        loading: false,
        transactions: action?.data
      })
    case actionTypes.GETTING_TRANSACTION_FAILURE:
      return updateObject(state, {
        loading: false,
        transactions: []
      })
    default:
      return state
  }
}

export default paymentReducer
