// theme custom
export const DATA_LOADING_START = 'DATA_LOADING_START'
export const DATA_LOADING_END = 'DATA_LOADING_END'
export const ERROR_MSG = 'ERROR_MSG'
export const SUCCESS_MSG = 'SUCCESS_MSG'

export const RESET_STATE = 'RESET_STATE'

// Dashboard
export const USER_DASHBOARD = 'USER_DASHBOARD'
export const USER_DASHBOARD_AVERAGE = 'USER_DASHBOARD_AVERAGE'
export const ADMIN_DASHBOARD = 'ADMIN_DASHBOARD'
export const CAMPAIGN_CREATED = 'CAMPAIGN_CREATED'

//Account page
export const ACCOUNTS_COUNT = 'ACCOUNTS_COUNT'

// Analytics campaign
export const ANALYTICS_COUNT = 'ANALYTICS_COUNT'

//Add campaign
export const ADD_NAME = 'ADD_NAME'
export const ADD_PLACEMENT = 'ADD_PLACEMENT'
export const ADD_GEO = 'ADD_GEO'
export const ADD_DEVICE = 'ADD_DEVICE'
export const ADD_IMPRESSION = 'ADD_IMPRESSION'
export const ADD_TIME_FRAME = 'ADD_TIME_FRAME'
export const OBJECTIVES_LIST = 'OBJECTIVES_LIST'
export const COUNTRY_LIST = 'COUNTRY_LIST'
export const REGION_LIST = 'REGION_LIST'
export const SELECTED_REGION = 'SELECTED_REGION'
export const INTEREST_LIST = 'INTEREST_LIST'
export const ADD_DAILY_BUDGET = 'ADD_DAILY_BUDGET'
export const ADD_CPM_BID = 'ADD_CPM_BID'
export const ADD_DESTINATION_URL = 'ADD_DESTINATION_URL'
export const ADD_CREATIVE_GALLERY = 'ADD_CREATIVE_GALLERY'
export const ADD_SOURCE = 'ADD_SOURCE'
export const ADD_ORIGIN = 'ADD_ORIGIN'
export const ADD_MEDIUM = 'ADD_MEDIUM'
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN'
export const ADD_TERM = 'ADD_TERM'
export const ADD_FINAL_DESTINATION_URL = 'ADD_FINAL_DESTINATION_URL'
export const ADD_BALANCE = 'ADD_BALANCE'
export const EDIT_BALANCE = 'EDIT_BALANCE'
export const ADD_TOTAL_BUDGET = 'ADD_TOTAL_BUDGET'
export const ADD_STATUS = 'ADD_STATUS'
export const ADD_HAS_APPROVED_ONCE = 'ADD_HAS_APPROVED_ONCE'
export const ADD_HAS_CHANGES = 'ADD_HAS_CHANGES'
export const UPDATE_COPY_NAME = 'UPDATE_COPY_NAME'
export const UPDATE_COPY_ID = 'UPDATE_COPY_ID'

//Campaign Detail
export const CAMPAIGN_DETAIL = 'CAMPAIGN_DETAIL'
export const CAMPAIGN_DETAILS_BY_IDS = 'CAMPAIGN_DETAILS_BY_IDS'
export const CAMPAIGN_DETAIL_SUCCESS = 'CAMPAIGN_DETAIL_SUCCESS'
export const CAMPAIGN_DETAIL_FAILURE = 'CAMPAIGN_DETAIL_FAILURE'
export const CLEAR_CAMPAIGN_DETAIL = 'CLEAR_CAMPAIGN_DETAIL'

//list CAMPAIGN_LIST
export const CAMPAIGN_LIST = 'CAMPAIGN_LIST'
export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE'

export const CAMPAIGN_GAM_STATUS = 'CAMPAIGN_GAM_STATUS'

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'

export const SELECTED_OBJECTIVE = 'SELECTED_OBJECTIVE'
export const SELECTED_INTEREST = 'SELECTED_INTEREST'

export const COPY_CAMPAIGN_SUCCESS = 'COPY_CAMPAIGN_SUCCESS'

export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS'
export const GET_ANALYTICS_CAMPAIGN_SUCCESS = 'GET_ANALYTICS_CAMPAIGN_SUCCESS'
export const CAMPAIGN_ANALYTICS_BY_IDS = 'CAMPAIGN_ANALYTICS_BY_IDS'
export const RESET_CAMPAIGN_DETAIL_AND_ANALYTICS_DATA = 'RESET_CAMPAIGN_DETAIL_AND_ANALYTICS_DATA'
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN'
export const ADD_DRAFT_SUCCESS = 'ADD_DRAFT_SUCCESS'
export const CLEAN_UP_DRAFT = 'CLEAN_UP_DRAFT'
// theme custom

//view ANALYTICS_LIST

export const VIEW_ANALYTICS_LIST = 'VIEW_ANALYTICS_LIST'

export const SIZE_LIST = 'SIZE_LIST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const SIGN_UP_USER_SUCCESS = 'SIGN_UP_USER_SUCCESS'
export const SIGN_UP_USER_FAILURE = 'SIGN_UP_USER_FAILURE'

export const RESET_PASSWORD_SENT_SUCCESS = 'RESET_PASSWORD_SENT_SUCCESS'
export const RESET_PASSWORD_SENT_FAILURE = 'RESET_PASSWORD_SENT_FAILURE'

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE'

export const GET_AUTH_TOKEN_SUCCESS = 'GET_AUTH_TOKEN_SUCCESS'

export const USER_ALREADY_LOGGED_IN_SUCCESS = 'USER_ALREADY_LOGGED_IN_SUCCESS'
export const USER_ALREADY_LOGGED_IN_FAILURE = 'USER_ALREADY_LOGGED_IN_FAILURE'

export const SETTING_USER_SUCCESS = 'SETTING_USER_SUCCESS'
export const SETTING_USER_FAILURE = 'SETTING_USER_FAILURE'

export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'

export const GETTING_USER_SUCCESS = 'GETTING_USER_SUCCESS'
export const GETTING_USER_FAILURE = 'GETTING_USER_FAILURE'

export const GETTING_USERS_SUCCESS = 'GETTING_USERS_SUCCESS'
export const GETTING_USERS_FAILURE = 'GETTING_USERS_FAILURE'

export const SET_ROLES = 'SET_ROLES'

export const ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS'
export const ADD_NEW_USER_FAILURE = 'ADD_NEW_USER_FAILURE'

export const ADD_CREATIVE_SUCCESS = 'ADD_CREATIVE_SUCCESS'
export const ADD_CREATIVE_FAILURE = 'ADD_CREATIVE_FAILURE'

export const LIST_CREATIVE_SUCCESS = 'LIST_CREATIVE_SUCCESS'

export const CREATIVE_DETAIL_SUCCESS = 'CREATIVE_DETAIL_SUCCESS'
export const CREATIVE_DETAIL_FAILURE = 'CREATIVE_DETAIL_FAILURE'

export const CREATIVE_LIST_BY_CAMPAIGN_SUCCESS = 'CREATIVE_LIST_BY_CAMPAIGN_SUCCESS'
export const CREATIVE_LIST_BY_CAMPAIGN_FAILURE = 'CREATIVE_LIST_BY_CAMPAIGN_FAILURE'

export const PAYMENT_CARD_LIST = 'PAYMENT_CARD_LIST'

export const GETTING_TRANSACTION_SUCCESS = 'GETTING_TRANSACTION_SUCCESS'
export const GETTING_TRANSACTION_FAILURE = 'GETTING_TRANSACTION_FAILURE'

export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS'
export const ADD_PAYMENT_FAILURE = 'ADD_PAYMENT_FAILURE'

//admin dashboard
export const GETTING_RECENT_DEPOSIT_SUCCESS = 'GETTING_RECENT_DEPOSIT_SUCCESS'
export const GETTING_RECENT_CAMPAIGN_SUCCESS = 'GETTING_RECENT_CAMPAIGN_SUCCESS'

//end admin dashboard

//CampFilter
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER'
export const RESET_SELECTED_FILTER = 'RESET_SELECTED_FILTER'
// end CampFilter

export const WARNING_MSG = 'WARNING_MSG'
export const CAMPAIGN_UPDATE_STATUS_CAMPAIGN = 'CAMPAIGN_UPDATE_STATUS_CAMPAIGN'
export const UPDATE_CAMPAIGN_All_DATA = 'UPDATE_CAMPAIGN_All_DATA'
export const SEARCH_RESULTS = 'SEARCH_RESULTS'
export const RESET_SEARCH = 'RESET_SEARCH'


// onBoarding
export const CALL_TOP_UP = 'CALL_TOP_UP';
export const STOP_TOP_UP = 'STOP_TOP_UP';

// Analytics campaign filter
export const SET_SELECTED_ANALYTICS_CAMPAIGNS = 'SET_SELECTED_ANALYTICS_CAMPAIGNS'