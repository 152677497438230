// action - state management
import { CampaignStatus } from '../../util/constants'
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'

interface actionType {
  type: string
  payload: any
}

export const initialState = {
  addCampaign: {
    name: '',
    device: ['Windows', 'Linux', 'Mac OS', 'Android', 'iOS', 'Other'],
    placement: '',
    geo: [],
    impression: 'Upto 20',
    timeFrame: 'Per Week',
    objectives: [],
    interests: [],
    dailyBudget: null,
    cpmBid: null,
    creativeGallery: [],
    origin: 'https://',
    destinationUrl: '',
    utm_source: 'dot',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    finalDestinationUrl: '',
    balance: null,
    total_budget: null,
    has_approved_once: false,
    status: CampaignStatus.PENDING_APPROVAL,
    hasChanges: false
  },
  loading: true,
  error: null,
  objectiveList: [],
  interestList: [],
  countries: [],
  region: [],
  campaignList: [],
  viewAnalyticsList: [],
  copyFromExisting: {
    parent_id: '',
    name: ''
  },
  selectedCampFilter: {
    status: [],
    objective: [],
    channel: [],
    budget: false
  },
  selectedAnalyticsCampaigns: [],
  selectedRegion: [],
  draftCampaign: {}
}

// ==============================|| ACCOUNT REDUCER ||============================== //

const campaignReducer = (state = initialState, action: actionType) => {
  switch (action.type) {

    case actionTypes.RESET_STATE : return initialState
    
    case actionTypes.ADD_NAME:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          name: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_PLACEMENT:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          placement: action.payload
        }
      })

    case actionTypes.ADD_GEO:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          geo: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_DEVICE:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          device: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_IMPRESSION:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          impression: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_TIME_FRAME:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          timeFrame: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.OBJECTIVES_LIST:
      return updateObject(state, {
        loading: false,
        objectiveList: action.payload,
        hasChanges: true
      })

    case actionTypes.SELECTED_OBJECTIVE:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          objectives: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.RESET_CAMPAIGN:
      return updateObject(state, {
        addCampaign: initialState.addCampaign,
        selectedRegion: initialState.selectedRegion
      })

    case actionTypes.INTEREST_LIST:
      return updateObject(state, {
        loading: false,
        interestList: action.payload,
        hasChanges: true
      })

    case actionTypes.COUNTRY_LIST:
      return updateObject(state, {
        loading: false,
        countries: action.payload,
        hasChanges: true
      })

    case actionTypes.REGION_LIST:
      return updateObject(state, {
        loading: false,
        region: action.payload,
        hasChanges: true
      })

    case actionTypes.SELECTED_INTEREST:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          interests: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_DAILY_BUDGET:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          dailyBudget: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_CPM_BID:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          cpmBid: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_CREATIVE_GALLERY:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          creativeGallery: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_ORIGIN:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          origin: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_DESTINATION_URL:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          destinationUrl: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_SOURCE:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          utm_source: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_MEDIUM:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          utm_medium: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_CAMPAIGN:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          utm_campaign: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_TERM:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          utm_term: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_FINAL_DESTINATION_URL:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          finalDestinationUrl: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_BALANCE:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          balance: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.EDIT_BALANCE:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          balance: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.ADD_TOTAL_BUDGET:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          total_budget: action.payload,
          hasChanges: true
        }
      })

    case actionTypes.CAMPAIGN_LIST:
      return updateObject(state, {
        loading: false,
        campaignList: action.payload
      })

    case actionTypes.VIEW_ANALYTICS_LIST:
      return updateObject(state, {
        loading: false,
        viewAnalyticsList: action.payload
      })

    case actionTypes.UPDATE_COPY_NAME:
      return updateObject(state, {
        copyFromExisting: {
          ...state.copyFromExisting,
          name: action.payload
        }
      })

    case actionTypes.UPDATE_COPY_ID:
      return updateObject(state, {
        copyFromExisting: {
          ...state.copyFromExisting,
          parent_id: action.payload
        }
      })

    case actionTypes.COPY_CAMPAIGN_SUCCESS:
      return updateObject(state, {
        copyFromExisting: initialState.copyFromExisting
      })

    case actionTypes.SET_SELECTED_FILTER:
      return updateObject(state, {
        loading: false,
        selectedCampFilter: action?.payload
      })

    case actionTypes.SET_SELECTED_ANALYTICS_CAMPAIGNS:
      return updateObject(state, {
        loading: false,
        selectedAnalyticsCampaigns: action?.payload
      })

    case actionTypes.RESET_SELECTED_FILTER:
      return updateObject(state, {
        loading: false,
        selectedCampFilter: {
          status: [],
          channel: [],
          objective: [],
          budget: false
        }
      })

    case actionTypes.UPDATE_CAMPAIGN_All_DATA:
      return updateObject(state, {
        loading: false
      })

    case actionTypes.SELECTED_REGION:
      return updateObject(state, {
        selectedRegion: action?.payload
      })

    case actionTypes.ADD_DRAFT_SUCCESS:
      return updateObject(state, {
        draftCampaign: action?.payload
      })

    case actionTypes.CLEAN_UP_DRAFT:
      return updateObject(state, {
        draftCampaign: {}
      })

    case actionTypes.ADD_STATUS:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          status: action.payload
        }
      })

    case actionTypes.ADD_HAS_APPROVED_ONCE:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          has_approved_once: action.payload
        }
      })

    case actionTypes.ADD_HAS_CHANGES:
      return updateObject(state, {
        addCampaign: {
          ...state.addCampaign,
          hasChanges: action.payload
        }
      })
    default:
      return state
  }
}

export default campaignReducer
