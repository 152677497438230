// action - state management
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'

export const initialState = {
  userDetails: {}
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const settingReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case actionTypes.RESET_STATE : return initialState
    
    case actionTypes.SETTING_USER_SUCCESS:
      return updateObject(state, {
        loading: false,
        userDetails: action.data
      })
    case actionTypes.EDIT_USER_SUCCESS:
      return updateObject(state, {
        loading: false,
        userDetails: action.data
      })
    default:
      return state
  }
}

export default settingReducer
