import { Box, Grid, Paper, Typography } from '@mui/material'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import TrustpilotRating from '../Elements/TrustpilotRating'
const ComaVector = process.env.PUBLIC_URL + 'images/comaVector.png'
const DotFrame = process.env.PUBLIC_URL + 'images/Frame2.webp'
const LogoTestimonial = process.env.PUBLIC_URL + 'images/Logotestimonial.webp'
const LogoTestimonial2 = process.env.PUBLIC_URL + 'images/testimonial1.webp'
const LogoTestimonial3 = process.env.PUBLIC_URL + 'images/testimonial2.webp'

gsap.registerPlugin(ScrollTrigger)

const data = [
  {
    id: '1',
    image: LogoTestimonial2,
    Name: 'Lynnae Sauer',
    Designation: 'Co-Founder at Bridges',
    Description:
      '"DOT provides actionable insights that help us to evolve our advertising campaigns - even when our ideas border on unconventional."'
  },
  {
    id: '2',
    image: LogoTestimonial3,
    Name: 'Mikhail Ovsepyan',
    Designation: 'Head of Expansion at TransferGo',
    Description: '"The CPI (cost per app install) is 50% better than all other channels, including Google"'
  },
  {
    id: '3',
    image: LogoTestimonial,
    Name: 'Žaneta Šupová',
    Designation: 'Marketing Manager at Trezor',
    Description: '“The best support and pro-client approach we could have asked for”'
  }
]

export default function Testimonials() {
  return (
    <Box sx={{ background: '#18181B' }}>
      <Box
        sx={{
          padding: { xs: '40px 10px', md: '40px 10px' }
        }}
        id="Testimonials"
        className="container"
      >
        <Box className="fade-bottom reveal" sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
          <Typography variant="h1" sx={{ color: '#fff', fontSize: { xs: '32px', md: '48px' }, mb: '24px' }}>
            The community has spoken
          </Typography>
          <Grid container sx={{ display: { xs: 'block', lg: 'flex' } }}>
            <Grid item xs={12} md={6} sx={{ m: { xs: '0 auto 20px', lg: 0 } }}>
              <Typography
                variant="h2"
                sx={{
                  color: '#fff',
                  fontSize: { xs: '16px', md: '32px' },
                  fontWeight: 'unset',
                  lineHeight: { xs: '32px', md: '38px' }
                }}
              >
                "You're going to love using DOT, but don't just take our word for it"
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ m: { xs: '20px auto 0px', lg: 0 } }}>
              <Grid container sx={{ display: { xs: 'block', md: 'flex' } }}>
                <Grid item xs={4} md={2} sx={{ margin: { xs: '30px auto', md: '0px' } }}>
                  <img
                    src={DotFrame}
                    alt="not displayDotFrame"
                    aria-label={'For DotFrameds'}
                    height={81}
                    width={81}
                  />
                </Grid>
                <Grid item xs={4} md={2} sx={{ maxWidth: '100% !important' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      mb: { xs: '40px', md: '0' }
                    }}
                  >
                    <Box sx={{ display: { xs: 'block', md: 'flex' }, p: '0 10px' }}>
                      <Typography variant="h3" sx={{ color: '#fff', fontSize: '48px', mr: '18px' }}>
                        12k+
                      </Typography>
                      <p style={{ color: '#fff', fontSize: '14px', lineHeight: '22px' }}>Campaigns Created</p>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'flex' }, p: '0 10px' }}>
                      <Typography variant="h3" sx={{ color: '#fff', fontSize: '48px', mr: '18px' }}>
                        1k+
                      </Typography>
                      <p style={{ color: '#fff', fontSize: '14px', lineHeight: '22px' }}>Accounts</p>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <TrustpilotRating textColor="light" />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        className="container"
        sx={{
          padding: { xs: '0px', md: '40px 0px' }
        }}
      >
        <Grid container>
          {data.map((user: any, i: any) => (
            <Grid className="reveal fade-bottom" item key={i} xs={12} md={4} lg={4} sx={{ p: 2 }}>
              <Paper sx={{ padding: '27px 30px', minHeight: '275px' }}>
                <img
                  src={ComaVector}
                  height="32px"
                  aria-label={'For DotFrame'}
                  width="36px"
                  alt="not displayDotFrame"
                />
                <Typography variant="h5" sx={{ fontSize: '16px', lineHeight: '23px', mt: 2, minHeight: '108px' }}>
                  {user.Description}
                </Typography>
                <Grid container className="container" sx={{ p: 0, mt: 2 }}>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sx={{
                      '& img': {
                        width: { xs: '50px', md: '60px', sm: '50px' },
                        height: { xs: '50px', md: '60px', sm: '50px' }
                      }
                    }}
                  >
                    <img aria-label={'For image'} src={user.image} alt="not displayimage" />
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <Typography variant="h3" sx={{ fontSize: { xs: '14px', md: '16px' } }}>
                      {user.Name}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: { xs: '12px', md: '14px' } }}>
                      {user.Designation}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
