import * as DotGQL from '../../../services/graphQL'
import { NOTIFICATIONS } from '../../../util/notification'
import * as actionTypes from '../../actionTypes'
import { loadingStart, loadingStop, notificationFail, notificationSuccess } from '../customization'

export const getCampaignList = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryCampaignList,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_LIST,
          payload: value?.data?.campaignList
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignListFail, error.message))
      })
  }
}

export const clearCampaignList = () => {
  return (dispatch: any) => {
    // dispatch({
    //   type: actionTypes.CAMPAIGN_LIST,
    //   payload: []
    // })
  }
}

export const clearStore = () => {
  return (dispatch: any) => {
    dispatch({
      type: actionTypes.RESET_STATE,
    })
    // dispatch({
    //   type: actionTypes.CAMPAIGN_LIST
    // })
  }
}


export const getCampaignDetail = (client: any, req: any) => {
  return (dispatch: any) => {
    // dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryCampaignDetail,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_DETAIL,
          payload: value?.data?.campaignDetail
        })
        // dispatch(loadingStop())
      })
      .catch((error: any) => {
        // dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignDetailFail, error.message))
      })
  }
}

// Get campaign details by ids also prevent the selected filter if already fetch
export const getCampaignDetailsByIds = (client: any, Ids: any, fetchedIDs: any) => {
  return async (dispatch: any, state: any) => {
    const stateValues = state()
    const queries = Ids.map((id: any) => {
      return client.query({
        query: DotGQL.queryCampaignDetail,
        fetchPolicy: 'no-cache',
        variables: { campaign_id: id }
      })
    })

    try {
      const result = await Promise.all(queries)

      const finalCampaignDetails = result.reduce((allCampaignsDetail, campaign, index) => {
        allCampaignsDetail[Ids[index]] = campaign.data.campaignDetail
        return allCampaignsDetail
      }, {})

      dispatch({
        type: actionTypes.CAMPAIGN_DETAILS_BY_IDS,
        payload: { ...stateValues.campaignDetailReducer.campaignDetailsByIDs, ...finalCampaignDetails }
      })
    } catch (error: any) {
      dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignDetailFail, error?.message))
    }
  }
}

export const resetCampaignDetailsAndAnlyticsData = () => {
  return async (dispatch: any) => {
    dispatch({
      type: actionTypes.RESET_CAMPAIGN_DETAIL_AND_ANALYTICS_DATA,
      payload: {}
    })
  }
}

export const syncCampaignSpentDetail = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.syncCampaignSpentDetail,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.CAMPAIGN_DETAIL,
          payload: value?.data?.syncCampaignSpentDetail
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignDetailFail, error.message))
      })
  }
}

export const updateCampaignStatusFilter = (value: any) => {
  return {
    type: actionTypes.SET_SELECTED_FILTER,
    payload: value
  }
}

export const ResetCampaignStatusFilter = () => {
  return {
    type: actionTypes.RESET_SELECTED_FILTER
  }
}

export const updateStatusCampaign = (client: any, req: any, callBack: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationUpdateStatusCampaign,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignStatusChange))
        dispatch(loadingStop())
        callBack()
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignDetailFail, error.message))
      })
  }
}
export const pauseUserCampaignStatus = (client: any, req: any, callBack: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationPauseUserCampaignStatus,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignStatusChange))
        dispatch(loadingStop())
        callBack()
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignDetailFail, error.message))
      })
  }
}

export const resumeUserCampaignStatus = (client: any, req: any, callBack: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .mutate({
        mutation: DotGQL.mutationResumeUserCampaignStatus,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch(notificationSuccess(NOTIFICATIONS.SUCCESS.campaignStatusChange))
        dispatch(loadingStop())
        callBack()
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignDetailFail, error.message))
      })
  }
}
export const getViewAnalyticsList = (client: any, req: any) => {
  return (dispatch: any) => {
    dispatch(loadingStart())
    client
      .query({
        query: DotGQL.queryCampaignList,
        fetchPolicy: 'no-cache',
        variables: req
      })
      .then((value: any) => {
        dispatch({
          type: actionTypes.VIEW_ANALYTICS_LIST,
          payload: value?.data
        })
        dispatch(loadingStop())
      })
      .catch((error: any) => {
        dispatch(loadingStop())
        dispatch(notificationFail(NOTIFICATIONS.ERROR.campaignListFail, error.message))
      })
  }
}

export const updateAnalyticsCampaignsFilter = (value: any) => {
  return {
    type: actionTypes.SET_SELECTED_ANALYTICS_CAMPAIGNS,
    payload: value
  }
}
