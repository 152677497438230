// action - state management
import * as actionTypes from '../actionTypes'
import { updateObject } from '../shared/utility'

interface actionType {
  type: string
  payload: any
}

export const initialState = {
  searchData: [],
  resetSearch: null
}

// ==============================|| COMMON REDUCER ||============================== //

const commonReducer = (state = initialState, action: actionType) => {
  switch (action.type) {

    case actionTypes.RESET_STATE : return initialState
    
    case actionTypes.SEARCH_RESULTS:
      return updateObject(state, {
        searchData: action.payload
      })
    case actionTypes.RESET_SEARCH:
      return updateObject(state, {
        resetSearch: action.payload
      })

    default:
      return state
  }
}

export default commonReducer
