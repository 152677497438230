import * as actionTypes from '../actionTypes'

export const initialState = {
    creativeDetail: null,
    creativeList: null,
    loading: false,
    error: null
}

// ==============================|| CREATIVE DETAIL REDUCER ||============================== //

const CreativeDetailReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case actionTypes.RESET_STATE : return initialState
        
        case actionTypes.CREATIVE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                creativeDetail: action?.data
            }
        case actionTypes.CREATIVE_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actionTypes.CREATIVE_LIST_BY_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                creativeList: action?.data
            }
        case actionTypes.CREATIVE_LIST_BY_CAMPAIGN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }
}

export default CreativeDetailReducer