// action - state management
import * as actionTypes from '../../actionTypes'
import { updateObject } from '../../shared/utility'

export const initialState = {
  accountCount: {}
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const accountCountReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case actionTypes.RESET_STATE : return initialState
    
    case actionTypes.ACCOUNTS_COUNT:
      return updateObject(state, {
        loading: false,
        accountCount: action.data
      })
    default:
      return state
  }
}

export default accountCountReducer
