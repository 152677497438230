import { CALL_TOP_UP, STOP_TOP_UP , RESET_STATE } from "../../actionTypes";
import { updateObject } from "../../shared/utility";

export const initialState = {
    topUpCalled: false
};

const onBoardingReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case RESET_STATE : return initialState

        case CALL_TOP_UP:
            return updateObject(state, {
                topUpCalled: true
            })
        case STOP_TOP_UP:
            return updateObject(state, {
                topUpCalled: false
            })
        default:
            return state;
    }
};

export default onBoardingReducer